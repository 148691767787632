<template>
  <div class="check">
    <div class="title">
      <div class="title_left">
        <div class="flex">
          <div class="flexBox" style="margin-right: 0">
            <div class="flexTitle">应用名称/ID</div>
            <el-input
              v-model="search_param.keyword"
              style="width: 100%"
              clearable
            />
          </div>
          <div class="flexBox" style="margin-right: 0">
            <div class="flexTitle">状态</div>
            <el-select
              v-model="search_param.is_lock"
              placeholder="所有"
              style="width: 100%"
              clearable
            >
              <el-option key="0" label="正常" :value="0" />
              <el-option key="1" label="冻结" :value="1" />
            </el-select>
          </div>

          <div class="flexBox" style="margin-right: 20px">
            <div class="flexTitle" style="width: 130px">创建时间</div>
            <el-date-picker
              v-model="search_param.create_time"
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
      <div class="title_right">
        <el-button
          v-debounce
          type="primary"
          style="margin-left: auto"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%; height: calc(100vh - 250px)"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange1"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="应用ID" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.app_id || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="应用名称" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.app_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建人" min-width="50px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.create_operator || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.create_time || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="最后修改人"
        min-width="80px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.update_operator || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="最后修改时间"
        min-width="80px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.update_time || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="40px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.is_lock || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="120px" fixed="right">
        <template v-slot:default="scope">
          <div style="cursor: pointer">
            <el-text
              type="primary"
              @click="rowClick(scope.row.al_id)"
              size="small"
              style="margin-right: 10px"
            >
              详情</el-text
            >
            <el-text
              v-if="scope.row.is_lock == '正常'"
              type="primary"
              @click="lockAddList(scope.row.al_id)"
              size="small"
              style="margin-right: 10px"
            >
              停用</el-text
            >
            <el-text
              v-if="scope.row.is_lock == '冻结'"
              type="primary"
              @click="lockAddList(scope.row.al_id)"
              size="small"
              style="margin-right: 10px"
            >
              启用</el-text
            >

            <el-text
              type="primary"
              @click="resetAppSecretList(scope.row.al_id)"
              size="small"
              style="margin-right: 10px"
            >
              重置秘钥</el-text
            >

            <el-text
              type="primary"
              @click="copyButton(scope.row.al_id)"
              size="small"
              style="margin-right: 10px"
            >
              复制密钥</el-text
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <!-- 抽屉详情 -->
    <el-drawer
      v-model="showDrawer"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 20px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>应用名称：{{ app_name }}</span>
            <span style="margin: 0 10px">|</span>
            <span>状态：{{ rowDetail.is_lock }}</span>
            <span style="margin: 0 10px">|</span>
            <span>创建时间：{{ rowDetail.create_time }}</span>
            <span style="margin: 0 10px">|</span>
            <span>创建人：{{ rowDetail.create_operator }}</span>
          </span>
        </div>
        <el-divider />
        <div style="margin-bottom: 30px; padding: 0 30px">
          <div
            style="
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div
              style="margin-right: 120px; display: flex; align-items: center"
            >
              <div style="font-weight: 700; margin-right: 10px; width: 100px">
                应用ID：
              </div>
              <div>
                <el-input
                  style="width: 400px"
                  v-model="rowDetail.app_id"
                  disabled
                ></el-input>
              </div>
            </div>

            <div>
              <el-button type="primary" @click="updateDetail"
                >更新信息</el-button
              >
              <el-button type="primary" plain @click="resetAppSecret"
                >重置秘钥</el-button
              >
              <el-button type="primary" plain @click="copyButton()"
                >复制秘钥信息</el-button
              >
              <el-button
                type="primary"
                v-if="rowDetail.is_lock == '正常'"
                plain
                @click="lockAdd"
                >停用</el-button
              >
              <el-button type="primary" v-else plain @click="lockAdd"
                >恢复</el-button
              >
            </div>
          </div>

          <div
            style="
              margin-right: 120px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
            "
          >
            <div style="font-weight: 700; margin-right: 10px; width: 100px">
              应用名称：
            </div>
            <div>
              <el-input
                style="width: 400px"
                v-model="rowDetail.app_name"
              ></el-input>
            </div>
          </div>

          <div
            style="
              margin-right: 120px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
            "
          >
            <div style="font-weight: 700; margin-right: 10px; width: 100px">
              秘钥：
            </div>
            <div>
              <el-input
                style="width: 400px"
                v-model="rowDetail.secret"
                disabled
              ></el-input>
            </div>
          </div>

          <div
            style="
              margin-right: 120px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
            "
          >
            <div style="font-weight: 700; margin-right: 10px; width: 100px">
              备注：
            </div>
            <div>
              <el-input
                style="width: 400px"
                v-model="rowDetail.note"
              ></el-input>
            </div>
          </div>

          <div
            style="
              margin-right: 120px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
            "
          >
            <div style="font-weight: 700; margin-right: 10px; width: 100px">
              最后修改时间：
            </div>
            <div>
              <el-input
                style="width: 400px"
                v-model="rowDetail.update_time"
                disabled
              ></el-input>
            </div>
          </div>

          <div
            style="
              margin-right: 120px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
            "
          >
            <div style="font-weight: 700; margin-right: 10px; width: 100px">
              最后修改人：
            </div>
            <div>
              <el-input
                style="width: 400px"
                v-model="rowDetail.update_operator"
                disabled
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import {
  add_list,
  add_detail,
  update_app,
  lock_add,
  get_app_secret,
  reset_app_secret,
} from "@/api/bank/app.js";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import useClipboard from "vue-clipboard3";

//查询参数对象
const search_param = reactive({
  keyword: "",
  is_lock: "",
  create_time: [],
  page: 1,
  size: 10,
});

// 重置功能
const reset = () => {
  search_param.keyword = "";
  search_param.is_lock = "";
  search_param.create_time = [];
};

const showDrawer = ref(false);
const selectId = ref("");
const rowClick = async (id) => {
  selectId.value = id;
  await getDetail();
  showDrawer.value = true;
};

const rowDetail = ref({
  app_id: "", // 应用企业id
  app_name: "", // 应用名称
  secret: "", // 秘钥
  note: "", // 备注
  update_time: "", // 备注
  update_operator: "", // 备注
});
// 获取详情
const app_name = ref("");
const getDetail = async () => {
  let res = await add_detail({ al_id: selectId.value });
  if (res.code == 200) {
    rowDetail.value = res.data;
    app_name.value = res.data.app_name;
  }
};

// 复制功能
const { toClipboard } = useClipboard();
const copyButton = async (al_id) => {
  try {
    let id = al_id ? al_id : selectId.value;
    let res = await get_app_secret({ al_id: id });

    await toClipboard(res.data.secret);
    ElMessage({
      grouping: true,
      showClose: true,
      message: "复制成功",
      type: "success",
    });
  } catch (e) {
    console.error(e);
  }
};

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
// 表格数据
let tableData = ref([]);
let multipleSelection1 = ref([]);
const handleSelectionChange1 = (val) => {
  multipleSelection1.value = val;
};
//获得数据列表
const getList = ({ cP = 1, size = 10, tips = false }) => {
  currentPage.value = cP;
  pageSize.value = size;
  let is_lock = search_param.is_lock === ""?null:search_param.is_lock;
  add_list({
    keyword: search_param.keyword || "",
    is_lock: is_lock,
    create_time: search_param.create_time || [],
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
    totalCount.value = res.data.count;
  });
};

const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};

// 获取详情
const updateDetail = async () => {
  let res = await update_app({
    al_id: selectId.value,
    app_name: rowDetail.value.app_name,
    note: rowDetail.value.note,
  });
  if (res.code == 200) {
    getDetail(selectId.value);
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
  }
};

// 获取详情
const resetAppSecret = async () => {
  let res = await reset_app_secret({ al_id: selectId.value });
  if (res.code == 200) {
    getDetail(selectId.value);
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
  }
};
const resetAppSecretList = async (al_id) => {
  let res = await reset_app_secret({ al_id: al_id });
  if (res.code == 200) {
    getList({ cP: currentPage.value, size: pageSize.value, tips: true });
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
  }
};

// 冻结解冻
const lockAdd = async () => {
  let res = await lock_add({ al_id: selectId.value });
  if (res.code == 200) {
    getDetail(selectId.value);
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
  }
};
// 冻结解冻
const lockAddList = async (al_id) => {
  let res = await lock_add({ al_id: al_id });
  if (res.code == 200) {
    getList({ cP: currentPage.value, size: pageSize.value, tips: true });
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
  }
};
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.title {
  display: flex;
}
.title_left {
  flex: 1;
}
.title_right {
  float: right;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
.tableHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
